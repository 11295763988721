exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/Products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/Projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/Services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-details-page-template-js": () => import("./../../../src/templates/DetailsPageTemplate.js" /* webpackChunkName: "component---src-templates-details-page-template-js" */)
}

